import styled, { css } from 'styled-components'

import MaxWidthContainer from '../MaxWidthContainer'

import { getColor, type ColorName } from 'theme'

/**
 * For use within a MaxWidthContainer to be able to provide a full-width background colour.
 */
const FullWidthBackground = ({
    children,
    backgroundColor,
    sticky,
    className,
}: {
    children: React.ReactElement
    backgroundColor: ColorName
    sticky?: boolean
    className?: string
}) => {
    return (
        <FullWidth $backgroundColor={backgroundColor} $sticky={sticky} className={className}>
            <MaxWidthContainer centraliseChildren>{children}</MaxWidthContainer>
        </FullWidth>
    )
}

const FullWidth = styled.div<{
    $backgroundColor: ColorName
    $sticky?: boolean
}>`
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
    position: relative;
    background-color: ${({ $backgroundColor }) => getColor($backgroundColor)};
    ${({ $sticky }) =>
        $sticky &&
        css`
            position: sticky;
            bottom: 0;
            z-index: 1000;
        `};
`

export default FullWidthBackground
